@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Lato:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');

/* @font-face {
  font-family: 'No Name 37 Regular';
  src: url('fonts/no_name_37_Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'No Name 37 Light';
  src: url('fonts/no_name_37_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
} */
body {
  font-family: 'Lato', sans-serif;
  background-color: rgb(212, 212, 212);
}
body:has(.requires-no-scroll) {
  overflow: hidden;
}
button:disabled {
  background-color: grey;
}
.nav {
  font-family: 'Lato', sans-serif;
}
.hb {
  position: relative;
}
.hb1 {
 border-radius: 5px;
 position: absolute;
 transform: translateY(0%);
 -webkit-transform: translateY(0%);
 -moz-transform: translateY(0%);
 -ms-transform: translateY(0%);
 -o-transform: translateY(0%);
 width: 100%;
 height: 8.33333333333%;
 background-color: rgb(212, 212, 212);
}
.hb2 {
 border-radius: 5px;
 position: absolute;
 transform: translateY(500%);
 -webkit-transform: translateY(500%);
 -moz-transform: translateY(500%);
 -ms-transform: translateY(500%);
 -o-transform: translateY(500%);
 width: 100%;
 height: 8.33333333333%;
 background-color: rgb(212, 212, 212);
}
.hb3 {
 border-radius: 5px;
 position: absolute;
 transform: translateY(1000%);
 -webkit-transform: translateY(1000%);
 -moz-transform: translateY(1000%);
 -ms-transform: translateY(1000%);
 -o-transform: translateY(1000%);
 width: 100%;
 height: 8.33333333333%;
 background-color: rgb(212, 212, 212);
}
.handwriting {
  font-family: 'Dancing Script', cursive;
}
@keyframes hb1 {
  0% {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
 -moz-transform: translateY(0%);
 -ms-transform: translateY(0%);
 -o-transform: translateY(0%);
  }
  50% {
    transform: translateY(500%);
    -webkit-transform: translateY(500%);
 -moz-transform: translateY(500%);
 -ms-transform: translateY(500%);
 -o-transform: translateY(500%);
  }
  100% {
    transform: translateY(500%) rotate(45deg);
    -webkit-transform: translateY(500%) rotate(45deg);
 -moz-transform: translateY(500%) rotate(45deg);
 -ms-transform: translateY(500%) rotate(45deg);
 -o-transform: translateY(500%) rotate(45deg);
  }
}
@keyframes hb2 {
    50% {
        opacity: 100;
    }
    100% {
        opacity: 0;
    }
}
@keyframes hb3 {
    0% {
      transform: translateY(1000%);
      -webkit-transform: translateY(1000%);
 -moz-transform: translateY(1000%);
 -ms-transform: translateY(1000%);
 -o-transform: translateY(1000%);
    }
    50% {
      transform: translateY(500%);
      -webkit-transform: translateY(500%);
 -moz-transform: translateY(500%);
 -ms-transform: translateY(500%);
 -o-transform: translateY(500%);
    }
    100% {
      transform: translateY(500%) rotate(-45deg);
      -webkit-transform: translateY(500%) rotate(-45deg);
 -moz-transform: translateY(500%) rotate(-45deg);
 -ms-transform: translateY(500%) rotate(-45deg);
 -o-transform: translateY(500%) rotate(-45deg);
    }
  }
  @keyframes hb1r {
    0% {
      transform: translateY(500%) rotate(-45deg);
      -webkit-transform: translateY(500%) rotate(-45deg);
 -moz-transform: translateY(500%) rotate(-45deg);
 -ms-transform: translateY(500%) rotate(-45deg);
 -o-transform: translateY(500%) rotate(-45deg);
    }
    50% {
      transform: translateY(500%) rotate(0deg);
      -webkit-transform: translateY(500%) rotate(0deg);
 -moz-transform: translateY(500%) rotate(0deg);
 -ms-transform: translateY(500%) rotate(0deg);
 -o-transform: translateY(500%) rotate(0deg);
    }
    100% {
      transform: translateY(0%) rotate(0deg);
      -webkit-transform: translateY(0%) rotate(0deg);
 -moz-transform: translateY(0%) rotate(0deg);
 -ms-transform: translateY(0%) rotate(0deg);
 -o-transform: translateY(0%) rotate(0deg);
    }
  }
  @keyframes hb2r {
      0% {
        opacity: 0
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 100;
      }
  }
  @keyframes hb3r {
      0% {
        transform: translateY(500%) rotate(45deg);
        -webkit-transform: translateY(500%) rotate(45deg);
 -moz-transform: translateY(500%) rotate(45deg);
 -ms-transform: translateY(500%) rotate(45deg);
 -o-transform: translateY(500%) rotate(45deg);
      }
      50% {
        transform: translateY(500%) rotate(0deg);
        -webkit-transform: translateY(500%) rotate(0deg);
    -moz-transform: translateY(500%) rotate(0deg);
    -ms-transform: translateY(500%) rotate(0deg);
    -o-transform: translateY(500%) rotate(0deg);
      }
      100% {
        transform: translateY(1000%) rotate(0deg);
        -webkit-transform: translateY(1000%) rotate(0deg);
    -moz-transform: translateY(1000%) rotate(0deg);
    -ms-transform: translateY(1000%) rotate(0deg);
    -o-transform: translateY(1000%) rotate(0deg);
      }
    }
    @keyframes slideLeft {
      from {
          transform: translateX(100%);
      }
      to {
          transform: translateX(0%);
      }
  }
  @keyframes slideRight {
      from {
          transform: translateX(0%);
      }
      to {
          transform: translateX(100%);
      }
  }
  @keyframes slideUp {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-100%);
    }
  }
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  .nav-link {
    transition: color 0.5s ease, transform 0.5s ease;
  }
  
  .nav-link:hover {
    color: #ccc;
    transform: scale(1.1);
  }
  

  .reservation-button {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  }
  
  .reservation-button:hover {
    background-color: #c82333; 
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .reservation-button:active {
    background-color: #a71d2a;
    transform: scale(0.95);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3)
  }
  
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  
.padding-border
{
  margin-top: 15px;
  background-color: black;
  border-radius: 30px;
  width: 400px;
  height:80px;
}
html {
  scroll-behavior: smooth;
}

@media not all and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

